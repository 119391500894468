import { template as template_0b959aa6e59b484292d893eaa0c4dd1d } from "@ember/template-compiler";
const FKText = template_0b959aa6e59b484292d893eaa0c4dd1d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
