import { template as template_6b5b7bc2c01d499eafb2c0e46507e078 } from "@ember/template-compiler";
const FKLabel = template_6b5b7bc2c01d499eafb2c0e46507e078(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
